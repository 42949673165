/*
Template Name: Ubold - Responsive Bootstrap 5 Admin Dashboard
Version: 6.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";

@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
